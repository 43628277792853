import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const MetaTags = () => {
  const location = useLocation();

  const metaData = {
    "/workshop": {
      title: "KŌBŌ",
      description:
        "Artisan workshop dedicated to expanding Web3 IPs one deck at a time.",
      image: "http://kobocraft.xyz/OpenGraph_workshop.jpg",
    },
    "/shop": {
      title: "KŌBŌ",
      description:
        "Artisan workshop dedicated to expanding Web3 IPs one deck at a time.",
      image: "http://kobocraft.xyz/OpenGraph_shop.jpg",
    },
  };

  const currentMeta = metaData[location.pathname] || metaData["/workshop"];

  return (
    <Helmet>
      <title>{currentMeta.title}</title>
      <meta content="website" property="og:type" />

      <meta name="description" content={currentMeta.description} />

      <meta content="KŌBŌ" property="og:site_name" />
      <meta property="og:image" content="https://kobocraft.xyz/OpenGraph.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:title" content={currentMeta.title} />
      <meta property="og:description" content={currentMeta.description} />
      <meta property="og:image" content={currentMeta.image} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@kobo_craft" />
      <meta name="twitter:creator" content="@kobo_craft" />
      <meta name="twitter:url" content="https://kobocraft.xyz" />
      <meta name="twitter:title" content={currentMeta.title} />
      <meta name="twitter:description" content={currentMeta.description} />
      <meta name="twitter:image" content={currentMeta.image} />
    </Helmet>
  );
};

export default MetaTags;
